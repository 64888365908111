import PromoBgXs from "../images/fpo/promo/huskerrs-promo-bg-xs.svg";
import PromoBgSm from "../images/fpo/promo/huskerrs-promo-bg-sm.svg";
import PromoBgMd from "../images/fpo/promo/huskerrs-promo-bg-md.svg";
import PromoBgLg from "../images/fpo/promo/huskerrs-promo-bg-lg.svg";
import PromoBgXl from "../images/fpo/promo/huskerrs-promo-bg-xl.svg";

import Img01 from "../images/fpo/warzone/cod-01.svg";
import Img02 from "../images/fpo/warzone/cod-02.svg";
import Img03 from "../images/fpo/warzone/cod-03.svg";
import Img04 from "../images/fpo/warzone/cod-04.svg";
import Img05 from "../images/fpo/warzone/cod-05.svg";
import Img06 from "../images/fpo/warzone/cod-06.svg";

import Avatar01 from "../images/fpo/avatar-01.svg";
import Avatar02 from "../images/fpo/avatar-02.svg";
import Avatar03 from "../images/fpo/avatar-03.svg";
import Avatar04 from "../images/fpo/avatar-04.svg";

export const INFLUENCER_DATA = {
  promo: {
    gameTitle: "Call Of Duty®: Warzone™",
    title: "Community Clash",
    host: "HusKerrs",
    prize: "$30,000",
    date: "Friday, September 24th 2021, 3pm PST",
    joinUrl: "https://app.partie.com/feed",
    backgroundSrc: {
      xs: PromoBgXs,
      sm: PromoBgSm,
      md: PromoBgMd,
      lg: PromoBgLg,
      xl: PromoBgXl,
    },
  },
  eventsTitle: "Meet the teams Call of Duty: Warzone",
  events: [
    {
      personality: {
        avatar: Avatar04,
        lastUpdate: "2h ago",
      },
      hostName: "Aydan",
      gameTitle: "Call of Duty",
      displayName: "Partie Call of Duty: Warzone World Cup",
      imgSrc: Img01,
      prize: "$1,000",
      players: [
        {
          avatar: Avatar01,
        },
        {
          avatar: Avatar02,
        },
        {
          avatar: Avatar03,
        },
        {
          avatar: Avatar04,
        },
        {
          avatar: Avatar02,
        },
      ],
      participantsCount: 2,
      maxPlayers: 32,
      time: "Every Wednesday, 3pm EST",
      shortUrl: "#",
    },
    {
      personality: {
        avatar: Avatar03,
        name: "waaaattson",
        lastUpdate: "2h ago",
      },
      hostName: "waaaattson",
      gameTitle: "Call of Duty",
      displayName: "Partie Call of Duty: Warzone World Cup",
      imgSrc: Img02,
      prize: "$1,000",
      players: [
        {
          avatar: Avatar01,
        },
        {
          avatar: Avatar02,
        },
        {
          avatar: Avatar03,
        },
        {
          avatar: Avatar04,
        },
        {
          avatar: Avatar02,
        },
      ],
      participantsCount: 2,
      maxPlayers: 'Any',
      time: "Every Wednesday, 3pm EST",
      shortUrl: "#",
    },
    {
      personality: {
        avatar: Avatar01,
        lastUpdate: "2h ago",
      },
      hostName: "Aydan",
      gameTitle: "Call of Duty",
      displayName: "Partie Call of Duty: Warzone World Cup",
      imgSrc: Img03,
      prize: "$1,000",
      players: [
        {
          avatar: Avatar01,
        },
        {
          avatar: Avatar02,
        },
        {
          avatar: Avatar03,
        },
        {
          avatar: Avatar04,
        },
        {
          avatar: Avatar02,
        },
      ],
      participantsCount: 2,
      maxPlayers: 32,
      time: "Every Wednesday, 3pm EST",
      shortUrl: "#",
    },
    {
      personality: {
        avatar: Avatar03,
        lastUpdate: "2h ago",
      },
      hostName: "Aydan",
      gameTitle: "Call of Duty",
      displayName: "Partie Call of Duty: Warzone World Cup",
      imgSrc: Img04,
      prize: "$1,000",
      players: [
        {
          avatar: Avatar01,
        },
        {
          avatar: Avatar02,
        },
        {
          avatar: Avatar03,
        },
        {
          avatar: Avatar04,
        },
        {
          avatar: Avatar02,
        },
      ],
      participantsCount: 2,
      maxPlayers: 32,
      time: "Every Wednesday, 3pm EST",
      shortUrl: "#",
    },
    {
      personality: {
        avatar: Avatar03,
        lastUpdate: "2h ago",
      },
      hostName: "Aydan",
      gameTitle: "Call of Duty",
      displayName: "Partie Call of Duty: Warzone World Cup",
      imgSrc: Img05,
      prize: "$1,000",
      players: [
        {
          avatar: Avatar01,
        },
        {
          avatar: Avatar02,
        },
        {
          avatar: Avatar03,
        },
        {
          avatar: Avatar04,
        },
        {
          avatar: Avatar02,
        },
      ],
      participantsCount: 5,
      maxPlayers: 32,
      time: "Every Wednesday, 3pm EST",
      shortUrl: "#",
    },
    {
      personality: {
        avatar: Avatar01,
        lastUpdate: "2h ago",
      },
      hostName: "clubAaron",
      gameTitle: "Call of Duty",
      displayName: "Partie Call of Duty: Warzone World Cup",
      imgSrc: Img06,
      prize: "$1,000",
      players: [
        {
          avatar: Avatar01,
        },
        {
          avatar: Avatar02,
        },
        {
          avatar: Avatar03,
        },
        {
          avatar: Avatar04,
        },
        {
          avatar: Avatar02,
        },
      ],
      participantsCount: 100,
      maxPlayers: 'Any',
      time: "Every Wednesday, 3pm EST",
      shortUrl: "#",
    },
  ],
};
