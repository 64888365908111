import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from 'axios';

import { PageLayout } from "../layouts";
import { GetStartedBlock, Block, AboutPartieBlock, EventsGrid, PromoBlock, TrackedLink } from '../components';

import EventImg from "../images/eventImg.svg";
import { INFLUENCER_DATA } from "../mockData/influencer-data";
import "../global/styles/page-styles/tournament-page.scss";

const API_ENDPOINT = process.env.API_ENDPOINT_EVENT;
const API_ENDPOINT_AVATAR_LIST = process.env.API_ENDPOINT_AVATAR_LIST;

const Events = () => {
    const [pageData, setPageData] = useState({ events: undefined });

    const pageLink = "/rules/general";
    const pageLinkText = "View Rules";
    const joinUrl = "https://app.partie.com/partie/ff76947e-aba9-49e8-9d45-3b722c898f90";
    const pageSize = 6;
    const userRole = 'Staff,StrategicPartner';

    /**
     *
     * Get Event Data when the component mounts.
     *
     */
    useEffect(() => {
        /**
         * 
         * Hyrdate Events Grid data.
         * Pass in Search Queries as query params.
         * 
         */
        axios.get(`${API_ENDPOINT}?userRoles=${userRole}`)
            .then((res) => {
                /**
                 * set data from tournament query
                 */
                /**
                * get the list of users inside room using collected data
                */
                for (let i = 0; i < res.data.length; i++) {
                    axios.get(`${API_ENDPOINT_AVATAR_LIST}?roomId=${res.data[i].id}`)
                        .then((people) => {
                            res.data[i].people = people.data;
                            setPageData({
                                events: res.data
                            });
                        })
                        .catch(error => {
                            console.log('Fetch error :: ', error);
                        })
                }
            })
            .catch(error => {
                console.log('Fetch error :: ', error);
            })
    }, []);

    return (
        <>
            <Helmet>
                <title>Partie | Events</title>
                <html lang="en" />
                <meta name="description" content="Looking for your next great gaming challenge? We’ve got you covered with recurring Community Play Events, Bounties and Tournaments. Find and assemble the perfect squad on Partie and go for glory" />
            </Helmet>
            <PageLayout className="tournament-page" hero>
                <Block hero lazy>
                    <div className="partie-block__column">
                        <h2 className="partie-block__title">
                            Exclusive Community Events
                        </h2>
                        <p className="partie-block__description">
                            Looking for your next great gaming challenge?
                            We’ve got you covered with recurring Community Play Events, Bounties and Tournaments.
                            Find and assemble the perfect squad on Partie and go for glory.
                        </p>
                        <div className="partie-block__cta">
                            <TrackedLink type="btn" to="/clash" children="Join now" />
                        </div>
                    </div>
                    <div className="partie-block__column">
                        <img src={EventImg} alt="Partie Event" />
                    </div>
                </Block>
                <AboutPartieBlock />
                {/* {INFLUENCER_DATA?.promo && <PromoBlock data={INFLUENCER_DATA.promo} pageLink={pageLink} pageLinkText={pageLinkText} joinUrl={joinUrl} />} */}
                {/* {INFLUENCER_DATA?.promo && <PromoBlock data={INFLUENCER_DATA.promo}/>} */}
                <Block centered className="events-block">
                    <h2 className="events-block__title">Upcoming Events</h2>
                    <EventsGrid events={pageData.events} length={pageSize} />
                </Block>
                <GetStartedBlock />
            </PageLayout>
        </>
    );
};

export default Events;